@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 13px;
  line-height: 1.6;
  background-color: #eee;
  justify-content: center;
  display: flex;
  align-items: center;
}

#root {
  width: 940px;
  background-color: white !important;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.blinkBox {
  animation: blink 0.25s step-end infinite alternate;
  border: 4px solid red;
  padding: none;
}

@keyframes blink {
  50% {
    border-color: yellow;
  }
}